* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}


body {
    background : var(--color-bg);
}

a {
    color: unset;
    text-decoration: none;
}


.gradient__bg {
   /* ff 3.6+ */
   background:-moz-radial-gradient(circle at 50% 50%, rgba(223, 223, 211, 1) 0%, rgba(213, 227, 244, 1) 50%, rgba(203, 198, 237, 1) 100%);

   /* safari 5.1+,chrome 10+ */
   background:-webkit-radial-gradient(circle at 50% 50%, rgba(223, 223, 211, 1) 0%, rgba(213, 227, 244, 1) 50%, rgba(203, 198, 237, 1) 100%);

   /* opera 11.10+ */
   background:-o-radial-gradient(circle at 50% 50%, rgba(223, 223, 211, 1) 0%, rgba(213, 227, 244, 1) 50%, rgba(203, 198, 237, 1) 100%);

   /* ie 10+ */
   background:-ms-radial-gradient(circle at 50% 50%, rgba(223, 223, 211, 1) 0%, rgba(213, 227, 244, 1) 50%, rgba(203, 198, 237, 1) 100%);

   /* global 92%+ browsers support */
   background:radial-gradient(circle at 50% 50%, rgba(223, 223, 211, 1) 0%, rgba(213, 227, 244, 1) 50%, rgba(203, 198, 237, 1) 100%);
   }

.gradient__text {
    background:var( --gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
  
.section__padding  {

    padding: 4rem 6rem;
}
.section__margin  {

    padding: 4rem 6rem;
}


.scale-down-center {
	-webkit-animation: scale-down-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-down-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
} 

 @-webkit-keyframes scale-down-center {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
  }
  @keyframes scale-down-center {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
  }
  
  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  
@media screen and (max-width: 700px){
    .section__padding {
        padding: 4rem;
    } 
    .section__margin {
        padding: 4rem ;
    }
}


@media screen and (max-width: 550px){
    .section__padding {
        padding: 4rem 2rem;
    } 
    .section__margin {
        padding: 4rem 2rem;
    }
}









































.gpt3__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 2rem 6rem;
}

.gpt3__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.gpt3__navbar-links_logo {
  margin-right: 2rem;
}

.gpt3__navbar-links_logo img {
  width: 62.56px;
  height: 16.02px;
}
/*
my journi logo 
*/
.gpt3__navbar-links_logo img {
  width: 120px;
  height: 100px;
}
.gpt3__navbar-links_container {
  display: flex;
  flex-direction: row;
}

.gpt3__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.gpt3__navbar-links_container p,
.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p {
  color: var(--color-nav-text);
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
/*
0 top and bot, 1 on right!
*/
  margin: 0 1rem;
  cursor: pointer;
}

.gpt3__navbar-sign button,
.gpt3__navbar-menu_container button {
  padding: 0.5rem 1rem; /*padding repalce width and height!*/
  color: var(--color-nav-text);
  background: #FF4820;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
}
.gpt3__navbar-menu {
  margin-left: 1rem;

  display: none;
  position: relative;
}

.gpt3__navbar-menu svg {
  cursor: pointer;
}

.gpt3__navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  
  text-align: end;
  background: var(--color-footer);
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 40px;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.gpt3__navbar-menu_container p {
  margin: 1rem 0;
}

.gpt3__navbar-menu_container-links-sign {
  display: none;
}











/* Shared */
.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #FFF;
}
.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
}
 
 
/* Google */
.loginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background: #DD4B39;
}
.loginBtn--google:before {
  border-right: #BB3F30 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png') 6px 6px no-repeat;
}
.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #E74B37;
}








@media screen and (max-width: 1050px) {
  .gpt3__navbar-links_container {
      display: none;
  }

  .gpt3__navbar-menu {
      display: flex;
  }
}

@media screen and (max-width: 700px) {
  .gpt3__navbar {
      padding: 2rem 4rem;
  }
}

@media screen and (max-width: 550px) {
  .gpt3__navbar {
      padding: 2rem;
  }

  .gpt3__navbar-sign {
      display: none;
  }

  .gpt3__navbar-menu_container {
      top: 20px;
  }

  .gpt3__navbar-menu_container-links-sign {
      display: block;
  }
}







